<template>
  <el-popover
    trigger="click"
    :show-arrow="false"
    :width="650"
    :disabled="props.isDisabled"
    :placement="props.placement"
    :offset="0.2"
    popper-class="elv-base-cascade-select-show-filter-popover"
    @before-enter="onCascadePanelShowStatusChange"
    @before-leave="onCascadePanelHideStatusChange"
  >
    <el-cascader
      v-bind="$attrs"
      ref="cascadeOptionsSelectRef"
      v-model="cascadeModel"
      class="elv-base-cascade-select-trigger-input"
      :class="[
        props.enabledFilter
          ? 'elv-base-cascade-select-trigger-input-filter'
          : 'elv-base-cascade-select-trigger-input-normal'
      ]"
      :popper-class="`elv-base-cascade-select-popper ${
        props.enabledFilter ? 'elv-base-cascade-select-show-filter-popper' : ''
      }`"
      :options="props.cascadeOptions"
      :props="selectProps"
      :filterable="props.enabledFilter"
      :placeholder="transformI18n(filterPlaceholder)"
      :teleported="false"
      :popper-append-to-body="false"
      @change="onSelectOptionChange"
    />
    <template #reference>
      <div :key="cascaderId" class="elv-base-cascade-select">
        <div
          v-if="isTagType"
          :class="[
            'elv-base-cascade-select-show-info-tags',
            props.class,
            { 'is-focus': isShowFilterInputDom },
            {
              'elv-base-cascade-select-show-info-tags-selected': isHightLightSelected,
              'elv-base-cascade-select-show-info-tags-selected-clean': isEnabledCleanSelect
            },
            {
              'elv-base-cascade-select-show-info-tags-disabled': props.isDisabled
            }
          ]"
        >
          <div v-if="selectedCascadeOptionList.length" class="elv-base-cascade-select-show-info-container">
            <div v-if="isMultipleSelect" class="elv-base-cascade-select-show-info-container-multiple-choice">
              <el-tag
                v-for="item in showMoreTagSelectList"
                :key="item[props.cascadeOptionValueName]"
                closable
                disable-transitions
                class="elv-base-cascade-select-show-info-item"
                @close="onDeleteCascadeItem(item)"
              >
                <span>{{ item[props.cascadeOptionLabelName] ?? '' }}</span>
              </el-tag>
              <el-tooltip
                v-if="isMoreSelected"
                effect="light"
                placement="bottom"
                popper-class="elv-base-cascade-select-show-info-container-more-tip"
                :show-after="500"
              >
                <el-tag class="elv-base-cascade-select-show-info-item-more-count" type="info" disable-transitions
                  >+{{ excessSelectCount }}</el-tag
                >
                <template #content>
                  <el-tag
                    v-for="moreCascadeItem in additionalSelectedItems"
                    :key="moreCascadeItem[props.cascadeOptionValueName]"
                    closable
                    disable-transitions
                    type="info"
                    effect="light"
                    @close="onDeleteCascadeItem(moreCascadeItem)"
                    >{{ moreCascadeItem[props.cascadeOptionLabelName] }}</el-tag
                  >
                </template>
              </el-tooltip>
            </div>
            <div v-else class="elv-base-cascade-select-show-info-container-single-choice">
              {{ selectedCascadeOptionsLabel }}
            </div>
          </div>
          <div v-else class="elv-base-cascade-select-show-info-item-empty">
            {{ transformI18n(props.selectPlaceholder) }}
          </div>
          <el-icon
            class="elv-base-cascade-select-arrow-icon"
            :size="14"
            :style="{ transform: rotate, transition: 'transform 0.3s ease-in-out' }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                fill="#a8abb2"
                d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z"
              ></path>
            </svg>
          </el-icon>
          <div
            v-if="selectedCascadeOptionList.length && props.enabledCleanSelect"
            class="elv-base-cascade-select-clear-btn"
            @click="onClearSelectCascadeOptions"
          >
            <SvgIcon name="group-clear" width="12" height="12" :fill="'#a8abb2'" />
          </div>
        </div>
        <div
          v-else
          class="elv-base-cascade-select-show-info-normal"
          :class="{ 'elv-base-cascade-select-show-info-normal-disabled': props.isDisabled }"
        >
          <div class="elv-base-cascade-select-show-info-item">
            <span>{{ selectedCascadeOptionsLabel }}</span>
          </div>
          <SvgIcon
            name="arrow-down-line"
            width="14"
            height="14"
            :style="{ transform: rotate, transition: 'transform 0.3s ease-in-out' }"
            :fill="props.isDisabled ? '#d0d3d6' : '#636B75'"
          />
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script setup lang="ts">
import { $t, transformI18n } from '@/i18n/index'

type EpPropMergeType =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'

const props = defineProps({
  class: { type: String, default: '' },
  isTagType: { type: Boolean, default: true },
  enabledFilter: { type: Boolean, default: true },
  selectSingleGroup: { type: Boolean, default: false },
  cascadeOptions: { type: Array<any>, default: () => [] as Array<any>, required: true },
  cascadeOptionValueName: { type: String, default: 'value' },
  cascadeOptionLabelName: { type: String, default: 'label' },
  cascadeOptionChildName: { type: String, default: 'children' },
  maxSelectCount: { type: Number, default: 2 },
  isMultipleSelect: { type: Boolean, default: true },
  enabledCleanSelect: { type: Boolean, default: true },
  isHighLight: { type: Boolean, default: true },
  isDisabled: { type: Boolean, default: false },
  maxTagWidth: { type: String, default: '150px' },
  selectHeight: { type: String, default: '44px' },
  selectWeight: { type: String, default: '100%' },
  placement: { type: String as PropType<EpPropMergeType>, default: 'bottom-start' },
  filterPlaceholder: { type: String, default: $t('placeholder.enterNameForSearch') },
  selectPlaceholder: { type: String, default: $t('placeholder.search') }
})

const { t } = useI18n()
const cascaderId = useId()

const rotate = ref('rotate(0deg)')
const isShowFilterInputDom = ref(false)
const selectedCascadeOptionList = ref<any[]>([])
const cascadeOptionsSelectRef = useTemplateRef('cascadeOptionsSelectRef')

const cascadeModel = defineModel<Array<any> | string>('cascadeModel', {
  required: true
})

const emit = defineEmits(['onChangeSelectCascadeOptions'])

const isMoreSelected = computed(() => selectedCascadeOptionList.value.length > props.maxSelectCount)
const excessSelectCount = computed(() => selectedCascadeOptionList.value.length - props.maxSelectCount)
const additionalSelectedItems = computed(() => selectedCascadeOptionList.value.slice(props.maxSelectCount))
const isHightLightSelected = computed(
  () => selectedCascadeOptionList.value.length && props.isHighLight && !props.isDisabled
)
const isEnabledCleanSelect = computed(
  () => selectedCascadeOptionList.value.length && props.enabledCleanSelect && !props.isDisabled
)
const selectedCascadeOptionsLabel = computed(() =>
  selectedCascadeOptionList.value.length
    ? selectedCascadeOptionList.value.map((item) => item[props.cascadeOptionLabelName]).join(', ')
    : t('common.select')
)

const selectProps = computed(() => ({
  multiple: props.isMultipleSelect,
  emitPath: false,
  value: props.cascadeOptionValueName,
  label: props.cascadeOptionLabelName,
  children: props.cascadeOptionChildName
}))

const showMoreTagSelectList = computed(() => {
  if (selectedCascadeOptionList.value.length > props.maxSelectCount) {
    return selectedCascadeOptionList.value.slice(0, props.maxSelectCount)
  }
  return selectedCascadeOptionList.value
})

/**
 * 筛选当前选择数据是否属于同一组
 */
const filterCascadeOptionBelongSameGroup = (selectList: Array<any>) => {
  if (!selectList.length || !props.selectSingleGroup || !props.isMultipleSelect) return
  const lastSelectId = selectList[selectList.length - 1]
  const parentGroupOfOption = props.cascadeOptions.find((item) =>
    item[props.cascadeOptionChildName].some((subItem: any) => subItem[props.cascadeOptionValueName] === lastSelectId)
  )
  if (!parentGroupOfOption) return

  const filteredSelectList = selectList.filter((item) =>
    parentGroupOfOption[props.cascadeOptionChildName].some(
      (filterItem: any) => filterItem[props.cascadeOptionValueName] === item
    )
  )

  cascadeModel.value = filteredSelectList
}

/**
 * 重置当前面板选择数据的数据源
 * @param selectList 当前选择数据
 */
const onResetSelectCascadeShowOptions = (selectList: any) => {
  if (!selectList) return
  selectedCascadeOptionList.value = props.cascadeOptions.flatMap((category) =>
    category[props.cascadeOptionChildName].filter((item: any) =>
      Array.isArray(cascadeModel.value)
        ? cascadeModel.value.includes(item[props.cascadeOptionValueName])
        : cascadeModel.value === item[props.cascadeOptionValueName]
    )
  )
}

/**
 * 选项发生变化时触发
 * @param selectList 当前选择数据
 */
const onSelectOptionChange = (selectList: any) => {
  filterCascadeOptionBelongSameGroup(selectList)
  emit('onChangeSelectCascadeOptions')
}

/**
 * 级联选择面板显示状态变化
 * 显示时，默认打开级联选择器第一级
 */
const onCascadePanelShowStatusChange = () => {
  isShowFilterInputDom.value = true
  rotate.value = 'rotate(180deg)'
  if (!cascadeModel.value?.length) {
    nextTick(() => {
      const menus: any = cascadeOptionsSelectRef.value?.cascaderPanelRef?.menus
      const child1 = menus[0][0]?.children
      const arr: Array<any> = []
      if (menus[0]) arr.push(menus[0])
      if (child1) arr.push(child1)
      if (cascadeOptionsSelectRef.value && cascadeOptionsSelectRef.value.cascaderPanelRef) {
        cascadeOptionsSelectRef.value.cascaderPanelRef.menus = arr
      }
    })
  }
}

/**
 * 级联选择面板隐藏状态变化
 */
const onCascadePanelHideStatusChange = () => {
  isShowFilterInputDom.value = false
  rotate.value = 'rotate(0deg)'
}

/**
 * 清空当前选择的级联数据
 * @param {Event} evt 点击对象
 */
const onClearSelectCascadeOptions = (evt: Event) => {
  evt?.stopPropagation()
  cascadeModel.value = []
  selectedCascadeOptionList.value = []
  emit('onChangeSelectCascadeOptions')
}

/**
 * 删除选择数据的其中一项
 * @param item 当前选择要删除的数据
 */
const onDeleteCascadeItem = (item: any) => {
  const index = selectedCascadeOptionList.value.findIndex(
    (i) => i[props.cascadeOptionValueName] === item[props.cascadeOptionValueName]
  )
  if (index !== -1) {
    selectedCascadeOptionList.value.splice(index, 1)
  }
  cascadeModel.value = selectedCascadeOptionList.value.map(
    (selectItem: any) => selectItem[props.cascadeOptionValueName]
  )
}

watch(
  () => cascadeModel.value,
  (newValue) => {
    onResetSelectCascadeShowOptions(newValue)
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-base-cascade-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: v-bind('selectWeight');
  height: v-bind('selectHeight');

  .elv-base-cascade-select-show-info-normal {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 106px;
    position: relative;

    &.elv-base-cascade-select-show-info-normal-disabled {
      color: #d0d3d6;
    }

    .elv-base-cascade-select-show-info-item {
      display: flex;
      max-width: calc(100% - 16px);
      align-items: center;
      height: 15px;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      color: #636b75;
      position: relative;

      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    svg {
      margin-left: 0px;
      margin-top: 5px;
    }
  }

  .elv-base-cascade-select-show-info-tags {
    width: 100%;
    height: 44px;
    border: 1px solid #dde1e6;
    background-color: #fff;
    box-shadow: 0 1px 3px #00000014;
    padding: 0px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
    }

    &.is-focus {
      border: 1.5px solid #1753eb;
    }

    &.elv-base-cascade-select-show-info-tags-selected {
      border-color: #5e85eb;
      background-color: #f7f9fe;
    }

    &.elv-base-cascade-select-show-info-tags-selected-clean {
      &:hover {
        .elv-base-cascade-select-clear-btn {
          visibility: visible;
        }

        .elv-base-cascade-select-arrow-icon {
          visibility: hidden;
        }
      }
    }

    &.elv-base-cascade-select-show-info-tags-disabled {
      background: #f9fafb;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    }

    .elv-base-cascade-select-show-info-container {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 6px;
      overflow: hidden;
      position: relative;

      .elv-base-cascade-select-show-info-item {
        height: 24px;
        border-radius: 3px;
        border: 1px solid #e3e7f1;
        background: #fff;
        padding: 0px 4px 0px 8px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        :deep(.el-tag__content) {
          max-width: v-bind('maxTagWidth');
          height: 14px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-weight: 400;
          color: #0e0f11;
        }

        :deep(.el-tag__close) {
          color: #aaafb6;

          &:hover {
            color: #fff;
            background-color: #909399;
          }
        }
      }

      .elv-base-cascade-select-show-info-item-more-count {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-weight: 400;
        color: #0e0f11;
        border: 1px solid #e3e7f1;
        background: #fff;
        border-radius: 3px;
      }

      .elv-base-cascade-select-show-info-container-multiple-choice {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .elv-base-cascade-select-show-info-container-single-choice {
        max-width: calc(100% - 20px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #0e0f11;
      }
    }

    .elv-base-cascade-select-show-info-item-empty {
      display: flex;
      align-items: center;
      height: 44px;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: #838d95;
    }

    .elv-base-cascade-select-clear-btn {
      width: 12px;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      right: 12px;
      border: 1px solid #a8abb2;
      border-radius: 50%;
      visibility: hidden;

      svg {
        fill: #a8abb2;
      }
    }
  }
}
</style>
<style lang="scss">
.elv-base-cascade-select-show-filter-popover {
  padding: 0px !important;
  overflow: visible;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.elv-base-cascade-select-trigger-input {
  height: 40px;
  position: absolute;

  &.elv-base-cascade-select-trigger-input-filter {
    display: block;

    .el-input--suffix::before {
      left: 12px;
      top: 32%;
    }
  }

  &.elv-base-cascade-select-trigger-input-normal {
    display: none;
  }

  .el-input {
    height: 40px;
    position: relative;

    &::before {
      content: '';
      display: inline;
      position: absolute;
      background-image: url('@/assets/icons/select-search.svg');
      background-size: cover;
      left: 8px;
      top: 30%;
      width: 14px;
      height: 14px;
      z-index: 2;
    }

    .el-input__wrapper {
      height: 40px;
      width: 650px;
      padding: 0px;
      padding-left: 30px;
      border: 1px solid #e4e7ed;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12) !important;
      border-radius: 4px 4px 0px 0px;

      .el-input__inner {
        height: 40px !important;
      }

      .el-input__suffix {
        display: none;
      }
    }
  }

  .el-cascader__tags {
    .el-tag {
      display: none;
    }

    .el-cascader__search-input {
      height: 40px;
      margin: 0px;
      margin-left: 30px;
    }
  }
}

.elv-base-cascade-select-popper {
  display: block !important;
  inset: 0px auto auto 0px !important;
  font-family: 'Plus Jakarta Sans';
  box-shadow:
    0px 4px 12px rgba(0, 0, 0, 0.12),
    0px -5px 0 rgba(0, 0, 0, 0) !important;
  border-top: 1px solid #dde1e6 !important;
  width: 650px;

  .el-cascader-menu {
    &:first-child {
      width: 240px;

      .el-cascader-node__label {
        font-weight: 600;
        font-size: 12px;
        color: #1e2024;
      }
    }

    &:last-child {
      flex: 1;
    }
  }

  .el-cascader-menu__list {
    padding: 0px;

    li[aria-hidden='true'] {
      display: none !important;
    }

    li {
      height: 32px;
      padding: 0px 8px !important;

      &.is-active {
        background-color: #f7f9fe;

        .el-cascader-node__label {
          color: #1e2024;
        }
      }

      .el-cascader-node__label {
        height: 32px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        color: #606266;
        padding-right: 0px;
      }

      .el-cascader-node__prefix {
        display: none;
      }

      svg {
        path {
          fill: #dce1e6;
        }
      }

      .el-icon.arrow-right.el-cascader-node__postfix {
        color: #dce1e6;
      }
    }
  }

  .el-cascader__suggestion-panel {
    .el-cascader__suggestion-item {
      height: 32px;
      color: #1e2024;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;

      &.is-checked {
        background-color: #edf0f3;
      }
    }
  }
}

.elv-base-cascade-select-show-filter-popper {
  inset: 40px auto auto 0px !important;
  border-radius: 0px 0px 4px 4px !important;
  border-top: none !important;
}

.elv-base-cascade-select-show-info-container-more-tip {
  max-width: 400px;
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
</style>
